import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { navigate, Link } from 'gatsby';
import Img from 'gatsby-image';

import {
  useGlobalState,
  useGlobalDispatch,
} from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider';
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer';
import ResponsiveVideo from '@babelcoder/gatsby-theme-base/src/components/ResponsiveVideo';
import useRegistration from '@babelcoder/gatsby-theme-courses/src/hooks/useRegistration';
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath';
import useSubscription from '@babelcoder/gatsby-theme-courses/src/hooks/useSubscription';
import courseTheme from '@babelcoder/gatsby-theme-courses/src/constants/theme';
import { SELL_STATUSES } from '@babelcoder/gatsby-theme-courses/src/constants/course';
import numToPrice from '@babelcoder/gatsby-theme-courses/src/utils/numToPrice';

const Header = styled.header`
  ${({ theme, bg }) => css`
    color: ${theme.colors.neutral.gray1500};

    ${up('large')} {
      background-color: ${bg};
      padding: ${courseTheme.gutter};
      padding-bottom: calc(${courseTheme.gutter} * 2);
    }
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-areas:
      'video'
      'title'
      'excerpt'
      'type-desc'
      'price'
      'register-button';
    position: relative;
    background-color: ${theme.colors.neutral.white};
    overflow: hidden;

    ${up('large')} {
      max-width: ${courseTheme.maxWidth.large};
      grid-template-areas:
        'video excerpt'
        'video type-desc'
        'video price'
        'video register-button';
      grid-template-columns: 65% 1fr;
      grid-template-rows: min-content;
      align-items: center;
      gap: 1rem;
      margin: 0 auto;
      border-radius: ${theme.round.xlarge};
    }
  `};
`;

const VideoWrapper = styled.div`
  grid-area: video;
`;

const Title = styled.h1`
  ${({ theme }) => css`
    grid-area: title;
    font-size: ${theme.fontSizes.normal};
    color: #000;
    margin: ${courseTheme.gutter} auto;
    padding: 0 ${courseTheme.gutter};
    line-height: 1.5;
    max-width: ${courseTheme.maxWidth.large};
    text-align: center;

    ${up('large')} {
      color: #fff;
      font-size: ${theme.fontSizes.medium};
      padding-left: 0;
      line-height: 1.25;
    }

    ${up('xlarge')} {
      color: #fff;
      font-size: ${theme.fontSizes.xlarge};
    }
  `}
`;

const Excerpt = styled.p`
  ${({ theme }) => css`
    grid-area: excerpt;
    margin: 0;
    padding: 0 ${courseTheme.gutter};
    font-size: ${theme.fontSizes.normal};
    color: ${theme.colors.neutral.gray1200};
    text-align: left;

    ${up('large')} {
      margin-top: 1rem;
      padding-left: 0;
    }
  `}
`;

const TypeDesc = styled.div`
  ${({ theme }) => css`
    position: relative;
    grid-area: type-desc;
    padding: 0 ${courseTheme.gutter};
    margin-top: 1rem;
    font-style: italic;
    font-size: 0.9rem;
    font-weight: bold;
    color: ${theme.colors.neutral.gray800};
    margin-bottom: -0.5rem;

    ${up('large')} {
      text-align: center;
      margin-top: 0;
      margin-bottom: -1rem;
      padding: 0;

      &::after {
        content: ' ';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 1px;
        background-color: ${theme.colors.neutral.gray700};
      }
    }
  `}
`;

const PriceArea = styled.div`
  grid-area: price;
  display: grid;
  grid-template-areas:
    'liveDesc livePrice'
    'liveTime liveTime'
    'videoDesc videoPrice';
  align-items: center;
  padding: 0 ${courseTheme.gutter};
`;

const LivePriceDesc = styled.div`
  ${({ theme }) => css`
    grid-area: liveDesc;
    font-size: ${theme.fontSizes.medium};

    ${up('large')} {
      padding-left: 0;
    }
  `}
`;

const LivePrice = styled.div`
  ${({ theme }) => css`
    grid-area: livePrice;
    font-family: ${theme.fontFamily.heading};
    align-self: flex-start;

    ${up('large')} {
      font-size: ${theme.fontSizes.medium};
      padding-left: 0;
    }
  `}
`;

const LiveTime = styled.div`
  ${({ theme }) => css`
    grid-area: liveTime;
    line-height: 0.25;
    padding-bottom: 1rem;
    font-style: italic;
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.neutral.gray900};
  `}
`;

const VideoPriceDesc = styled.div`
  ${({ theme }) => css`
    grid-area: videoDesc;
    font-size: ${theme.fontSizes.medium};

    ${up('large')} {
      padding-left: 0;
    }
  `}
`;

const VideoPrice = styled.div`
  ${({ theme }) => css`
    grid-area: videoPrice;
    font-family: ${theme.fontFamily.heading};

    ${up('large')} {
      font-size: ${theme.fontSizes.medium};
      padding-left: 0;
    }
  `}
`;

function getSellStatusColor(sellStatus, theme) {
  switch (sellStatus) {
    case SELL_STATUSES.INACTIVE:
      return theme.colors.main.danger;
    case SELL_STATUSES.PENDING:
      return theme.colors.main.info;
    case SELL_STATUSES.ACTIVE:
    default:
      return theme.colors.main.primary;
  }
}

const statusButtonStyle = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium};
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    margin: ${courseTheme.gutter};
    color: ${theme.colors.neutral.white};
    text-align: center;
    border-radius: 2rem;
    border: none;
    outline: none;

    ${up('large')} {
      margin: 0 ${courseTheme.gutter} ${courseTheme.gutter} 0;
    }
  `}
`;

const StatusButton = styled.button.attrs(({ sellStatus }) => ({
  type: 'button',
  disabled: sellStatus !== SELL_STATUSES.ACTIVE,
}))`
  ${statusButtonStyle};

  ${({ theme, sellStatus }) => css`
    grid-area: register-button;
    background-color: ${getSellStatusColor(sellStatus, theme)};
    ${sellStatus === SELL_STATUSES.ACTIVE && `cursor: pointer`};
  `}
`;

const RegistrationPending = styled.div`
  ${statusButtonStyle};

  background-color: ${({ theme }) => theme.colors.main.info};
`;

const OverViewLink = styled(Link)`
  ${statusButtonStyle};

  background-color: ${({ theme }) => theme.colors.main.primary};
`;

const Note = styled.article`
  ${({ theme }) => css`
    background-color: ${theme.colors.accent.primaryDarken15};
    padding: ${theme.spacers.small};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.normal};
    letter-spacing: 1px;
    text-align: center;
  `}
`;

function getStatusMessage(sellStatus) {
  switch (sellStatus) {
    case SELL_STATUSES.INACTIVE:
      return 'งดจำหน่าย';
    case SELL_STATUSES.PENDING:
      return 'อยู่ระหว่างจัดทำ';
    case SELL_STATUSES.ACTIVE:
    default:
      return 'ลงทะเบียน';
  }
}

function CourseHeader({
  slug,
  title,
  excerpt,
  note,
  livePrice,
  videoPrice,
  liveTime,
  promotedVideo,
  promotedImage,
  sellStatus,
  style: {
    header: { bgColor },
  },
}) {
  const isSSR = typeof window === 'undefined';
  const { user, isAuthLoaded } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const { subscription, isLoading: isSubscriptionLoading } = useSubscription(
    slug
  );
  const {
    isLoading: isRegistrationLoading,
    exists: registrationExists,
  } = useRegistration(slug);
  const {
    getCoursePath,
    getCourseRegisterPath,
    getCourseOverviewPath,
  } = useCoursesPath();

  const register = useCallback(() => {
    if (sellStatus !== SELL_STATUSES.ACTIVE) return;

    if (!user) {
      dispatch({
        type: actions.showPopupMessage,
        popupMessage: {
          type: 'warning',
          title: 'ต้องการการยืนยันตัวตน',
          message: 'โปรดเข้าสู่ระบบก่อนดำเนินการลงทะเบียน',
        },
      });
      navigate('/auth/sign-in', { state: { prevPath: getCoursePath(slug) } });
      return;
    }

    if (typeof fbq === 'function') {
      // eslint-disable-next-line no-undef
      fbq('track', 'InitiateCheckout', {
        content_ids: [slug],
        content_name: title,
        value: livePrice,
        currency: 'THB',
      });
    }
    navigate(getCourseRegisterPath(slug));
  }, [user, dispatch, slug, sellStatus, getCourseRegisterPath, getCoursePath]);

  const getStatus = useMemo(() => {
    if (!isAuthLoaded) return null;
    if (isSubscriptionLoading) return null;

    if (subscription) {
      return (
        <OverViewLink to={getCourseOverviewPath(slug)}>เริ่มเรียน</OverViewLink>
      );
    }
    if (isRegistrationLoading) return null;

    if (registrationExists) {
      return (
        <RegistrationPending>กำลังตรวจสอบการลงทะเบียน</RegistrationPending>
      );
    }

    return (
      <StatusButton sellStatus={sellStatus} onClick={register}>
        {getStatusMessage(sellStatus)}
      </StatusButton>
    );
  }, [
    slug,
    isAuthLoaded,
    isRegistrationLoading,
    registrationExists,
    sellStatus,
    register,
    subscription,
    isSubscriptionLoading,
    getCourseOverviewPath,
  ]);

  return (
    <>
      {note && <Note>{note}</Note>}
      <Header bg={bgColor}>
        <Title>{title}</Title>
        <Container>
          <VideoWrapper>
            {promotedImage ? (
              <Img fluid={promotedImage.childImageSharp.fluid}></Img>
            ) : (
              !isSSR && (
                <React.Suspense fallback={<div />}>
                  <ResponsiveVideo
                    url={promotedVideo}
                    title={title}
                    desc={excerpt}
                    controls={true}
                  ></ResponsiveVideo>
                </React.Suspense>
              )
            )}
          </VideoWrapper>
          <Excerpt>{excerpt}</Excerpt>
          <TypeDesc>สามารถเลือกการเรียนแบบใดแบบหนึ่ง คือ</TypeDesc>
          <PriceArea>
            <LivePriceDesc>รอบสอนสดออนไลน์</LivePriceDesc>
            <LivePrice>{numToPrice(livePrice)} ฿</LivePrice>
            <LiveTime>(วันอบรม {liveTime})</LiveTime>
            <VideoPriceDesc>วิดีโอบันทึกการสอน</VideoPriceDesc>
            <VideoPrice>{numToPrice(videoPrice)} ฿</VideoPrice>
          </PriceArea>
          {getStatus}
        </Container>
      </Header>
    </>
  );
}

export default CourseHeader;
