import React from 'react';
import { graphql } from 'gatsby';

import Course from '@babelcoder/gatsby-theme-courses/src/components/courses/classroom/Course';

export default function CourseTemplate({ data: { course } }) {
  return <Course {...course}></Course>;
}

CourseTemplate.layout = {
  headerType: 'disabledShadowOnTopHeader',
};

export const pageQuery = graphql`
  query ClassRoomCourseQuery($slug: String!) {
    course: coursesYaml(slug: { eq: $slug }) {
      slug
      title
      details
      excerpt
      note
      livePrice
      videoPrice
      liveTime
      promotedVideo
      promotedImage {
        childImageSharp {
          fluid(maxWidth: 768, quality: 50, webpQuality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sellStatus
      tableOfContents
      coverImage: image {
        childImageSharp {
          fixed(width: 730, height: 415, quality: 50, webpQuality: 50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      style {
        header {
          bgColor
        }
      }
      units: promotedUnits {
        day
        units {
          desc
          title
          lessons {
            title
            desc
          }
        }
      }
    }
  }
`;
